import './App.css';
import React from 'react';

function App() {
    return (
        <p>Hello, world!</p>
    );
}

export default App;
